import React from "react"
import { Link } from "gatsby"
import loadable from "@loadable/component"
import { Container, Row, Col, Form } from "react-bootstrap"
import { filterCustomStyles } from "../components/DropdownSelectSettings/DropdownSelectSettings"
import sortByOptions from "../search_config/sort_options.json"
import Layout from "../components/layout"

import PropertyImg1 from "../images/featured_properties_img_1.png"
import PropertyImg2 from "../images/featured_properties_img_2.png"
import PropertyImg3 from "../images/featured_properties_img_3.png"
import PropertyImg4 from "../images/property_img_1.png"
import PropertyImg5 from "../images/property_img_2.png"
import PropertyImg6 from "../images/property_img_3.png"
import PropertyImg7 from "../images/property_img_4.png"
import PropertyImg8 from "../images/property_img_5.png"
import PropertyImg9 from "../images/property_img_6.png";
import PropertyImg10 from "../images/property_img_7.png";

const Select = loadable(() => import("react-select"))
const FilterSearchNewHomes = loadable(() => import("../components/FilterSearch/FilterSearchNewHomes"))
const SearchResultsHeading = loadable(() => import("../components/SearchResultsHeading/SearchResultsHeading"))
const NewHomesPropertyCount = loadable(() => import("../components/NewHomesPropertyCount/NewHomesPropertyCount"))
const NewHomesCard = loadable(() => import("../components/NewHomesCard/NewHomesCard"));
const CtaModule = loadable(() => import("../components/CtaModule/CtaModule"))
const SearchResultsPagination = loadable(() => import("../components/SearchResultsPagination/SearchResultsPagination"))
const NewsLetterModule = loadable(() => import("../components/NewsLetterModule/NewsLetterModule"))

const NewHomeResults = () => {

    const allProperty = [
        {
            image: PropertyImg1,
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            price: 'Price from £595,000 - £3,950,000',
            title: '2-4 bedroom houses for sale',
        },
        {
            image: PropertyImg2,
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            price: 'Price from £595,000 - £3,950,000',
            title: '2-4 bedroom houses for sale',
        },
        {
            image: PropertyImg3,
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            price: 'Price from £595,000 - £3,950,000',
            title: '2-4 bedroom houses for sale',
        },
        {
            image: PropertyImg4,
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            price: 'Price from £595,000 - £3,950,000',
            title: '2-4 bedroom houses for sale',
        },
        {
            image: PropertyImg5,
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            price: 'Price from £595,000 - £3,950,000',
            title: '2-4 bedroom houses for sale',
        },
        {
            image: PropertyImg6,
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            price: 'Price from £595,000 - £3,950,000',
            title: '2-4 bedroom houses for sale',
        },
        {
            image: PropertyImg7,
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            price: 'Price from £595,000 - £3,950,000',
            title: '2-4 bedroom houses for sale',
        },
        {
            image: PropertyImg8,
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            price: 'Price from £595,000 - £3,950,000',
            title: '2-4 bedroom houses for sale',
        },
        {
            image: PropertyImg9,
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            price: 'Price from £595,000 - £3,950,000',
            title: '2-4 bedroom houses for sale',
        },
        {
            image: PropertyImg10,
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            price: 'Price from £595,000 - £3,950,000',
            title: '2-4 bedroom houses for sale',
        }
    ]

    return (
        <Layout
            layout={"sticky-module"}
        >
            <div className="layout-padding-top"></div>

            <div className='layout-grey-bg-theme'>
                <FilterSearchNewHomes />

                <div className="search-results-wrapper">
                    <Container>
                        <Row>
                            <Col xl={7}>
                                <SearchResultsHeading tag="new-homes" />
                            </Col>
                        </Row>

                        <div className="new-homes-heading-wrapper">
                            <Row>
                                <Col md={6}>
                                    <NewHomesPropertyCount />
                                </Col>
                                <Col md={6} className="d-flex justify-content-md-end align-items-start">
                                    <ul className="list-inline search-sort-list d-flex align-items-center">
                                        <li className="list-inline-item">
                                            <div className="dropdown-select d-flex align-items-center search-results-sort">
                                                <Select
                                                    options={sortByOptions}
                                                    isSearchable={false}
                                                    placeholder={"Recently Added"}
                                                    className={"select-control"}
                                                    classNamePrefix={"react-select"}
                                                    styles={filterCustomStyles}
                                                    components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown-dark"></i>, IndicatorSeparator:() => null }}
                                                />
                                            </div>
                                        </li>

                                        <li className="list-inline-item">
                                            <div className="search-divider"></div>
                                        </li>

                                        <li className="list-inline-item">
                                            <Link to="" className="search-map"><i className="icon icon-map"></i> <span>Map</span></Link>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Container>

                    <Container>
                        <div className="new-homes-card-wrapper-main">
                            {
                                allProperty.map((property, i) => {
                                    let v = i+1;

                                    return (
                                        <>
                                            <NewHomesCard newHomesCard={property} key={i} />
                                            {
                                                v > 0 && (v % 4) == 0 &&
                                                <CtaModule tag="new-homes" />
                                            }
                                        </>
                                    )
                                })
                            }

                            <SearchResultsPagination
                                currentPage={"1"}
                                itemsPerPage={"5"}
                                totalItems={"10"}
                                setCurrentPage={"1"}
                            />
                        </div>
                    </Container>
                </div>
            </div>

            <NewsLetterModule />
        </Layout>
    )
}

export default NewHomeResults